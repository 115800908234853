.ova-instagram {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	grid-gap: 10px;
	a {
		position: relative;
		border-radius: 5px;
		img {
			border-radius: 5px;
		}
		&:before {
			position: absolute;
			font-family: fontAwesome;
			content: "\f16d";
			font-size: 18px;
			color: #fff;
			top: 50%;
			left: 50%;
			transform: translate(-50%,-50%);
			opacity: 0;
			transition: 0.4s;
			z-index: 2;
		}
		&:after {
			position: absolute;
			content: "";
			width: 100%;
			height: 100%;
			background: rgba(255,255,255,0.4);
			top: 0px;
			left: 0px;
			opacity: 0;
			transition: 0.4s;
			z-index: 1;
			border-radius: 5px;
		}
		&:hover {
			&:before, &:after {
				opacity: 1;
			}
		}
	}
}