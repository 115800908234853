.ova-menu-acount {
	display: flex;
	align-items: center;
	.login , .register, .my-account {
		display: flex;
		align-items: center;
		i {
			font-size: 14px;
			margin-right: 8px;
		}
		a {
			font-size: 14px;
			line-height: 1.2;
			color: #333333;
			transition: 0.4s;
			&:hover {
				color: #e86c60;
			}
		}
	}

	.my-account{
		img{
			border-radius: 100%;
			margin-right: 8px;
		}
	}

	.register{
		@media( max-width: 767.98px){
			display: none;
		}
	}
	.slash {
		font-size: 12px;
		color: #d1d1d1;
		padding: 0 9px;
		@media( max-width: 767.98px){
			display: none;
		}
	}

}