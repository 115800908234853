.ova-about-team {
	text-align: center;
	&:hover {
		.ova-media {
			.image {
				&:after {
					opacity: 1;
				}
				.social {
					opacity: 1;
				}
			}

		}
	}
	.ova-media {
		margin-bottom: 36px;
		.image {
			display: block;
			position: relative;
			&:after {
				position: absolute;
				top: 0;
				left: 0;
				content: "";
				width: 100%;
				height: 100%;
				background: rgba(0,0,0,0.5);
				border-radius: 5px;
				z-index: 1;
				transition: 0.4s;
				opacity: 0;
			}
			img {
				border-radius: 5px;
			}
			
			.social {
				padding: 0px;
				margin: 0px;
				list-style-type: none;
				display: flex;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				z-index: 2;
				transition: 0.4s;
				opacity: 0;
				li {
					&:not(:last-child) {
						margin-right: 18px;
					}
					a {
						i {
							font-size: 18px;
							color: #ffffff;
							transition: 0.4s;
						}
					}
				}
			}
		}
		
	}
	.content {
		.name {
			margin: 0px;
			font-size: 22px;
			line-height: 1.2;
			font-weight: 600;
			color: #333333;
			margin-bottom: 14px;
		}
		.job {
			margin: 0px;
			font-size: 16px;
			line-height: 1.2;
			color: #888888;
		}
	}
}