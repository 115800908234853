.ova-skill-bar {
  .text-skill-bar {
    font-size: 20px;
    line-height: 28px;
    color: #222222;
    margin-bottom: 23px;
    text-transform: uppercase;
    font-weight: 600;
  }
  .cove-killbar {
    background: #ebebeb;
    border-radius: 10px;
    .skillbar-bar {
      height:5px;
      width:0px;
      background: #e86c60;
      border-radius: 10px;
    }
  }
  .skillbar {
    position:relative;
    display:block;
    width:100%;
    &:not(:last-child) {
      margin-bottom: 33px;
    }
  }

  .percent {
    position: absolute;
    top: 0;
    left:0;

    .relative {
      position: relative;
      span {
        font-size: 20px;
        line-height: 28px;
        color: #333333;
        background: transparent;
        position: absolute;
        left: -27px;
      }
    }

  }
}

