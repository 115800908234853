.ova-contact {
	display: flex;
	align-items: center;
	.icon {
		display: inline-flex;
		i {
			display: inline-block;
			line-height: 1;
			margin-right: 15px;
			&:before {
				margin: 0px;
				font-size: 18px;
			}
		}
	}
	.address {
		margin: 0px;
		font-size: 16px;
		line-height: 24px;
		color: #333333;
		font-weight: 400;
		a {
			font-size: 16px;
			line-height: 24px;
			color: #333333;
			transition: 0.4s;
		}
	}
}