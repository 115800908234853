.ova-offer-banner2 {
	display: flex;
	flex-wrap: wrap;
	text-align: right;
	padding: 27px 34px 24px 20px;
	background-position: center center;
	background-size: cover;
	background-repeat: no-repeat;
	p {
		margin: 0px;
	}
	@media (max-width: 767px) {
		text-align: left;
	}
	.content-1 {
		flex: 0 0 calc(100% - 200px);
		@media (max-width: 767px) {
			flex: 0 0 100%;
			order: 2;
		}
		.title-1 {
			font-size: 30px;
			line-height: 44px;
			font-weight: 500;
			color: #ffffff;
			text-transform: uppercase;
			position: relative;
			&:after {
				position: absolute;
				top: 11px;
				right: -24px;
				content: "";
				background: #ffd13c;
				width: 1px;
				height: 120px;
				@media (max-width: 767px) {
					display: none;
				}
			}
			
		}
		.sub-title-1 {
			font-size: 20px;
			line-height: 30px;
			font-weight: 500;
			color: #fff6da;
			text-transform: uppercase;
			margin-bottom: 29px;
			position: relative;
			&:after {
				position: absolute;
				bottom: -11px;
				right: 0px;
				content: "";
				background: #ffd13c;
				width: 42px;
				height: 1px;
				@media (max-width: 767px) {
					display: none;
				}
			}
		}
		.sub-title-2 {
			font-size: 22px;
			line-height: 30px;
			font-weight: 500;
			color: #fff6da;
		}
	}
	.content-2 {
		flex: 0 0 200px;
		@media (max-width: 767px) {
			flex: 0 0 100%;
			order: 1;
			margin-bottom: 20px;
		}
		.title-2 {
			font-size: 42px;
			line-height: 44px;
			font-weight: 500;
			color: #ffffff;
			text-transform: uppercase;
		}
	}
}